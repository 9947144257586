import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    customRender: (value, item, index) => index + 1,
  },
  {
    title: T("批次编号"),
    dataIndex: "number",
  },
  {
    title: T("仓库"),
    dataIndex: "warehouse_name",
  },
  {
    title: T("货品名称"),
    dataIndex: "material_name",
    ellipsis: true,
  },
  {
    title: T("货品编号"),
    dataIndex: "material_number",
  },
  {
    title: T("货主"),
    dataIndex: "client_name",
  },
  {
    title: T("库存数量"),
    dataIndex: "remain_quantity",
  },
  {
    title: T("库存单位"),
    dataIndex: "material_name",
  },
  {
    title: T("生产日期"),
    dataIndex: "production_date",
  },
  {
    title: T("到期日期"),
    dataIndex: "expiration_date",
  },
  {
    title: T("操作"),
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: 100,
  },
];
